export const Pages = {
    HOME: '/',
    DASHBOARD: '/dashboard',
    JOBS: '/jobs',
    RESUMES: '/resumes',
    MATCH: '/match/:id',
    SKILL_MATCH: '/skill-match'
}

export const JobsterLink = {
    ABOUT_US: 'http://beta.jobster.io/about-us',
    PRIVACY_POLICY: 'http://beta.jobster.io/privacy-policy',
    TERMS_OF_USE: 'http://beta.jobster.io/terms-of-use'
}

export const ExternalLink = {
    KE_HOME: 'https://kesolutions.ca/home',
    FACEBOOK: 'https://www.facebook.com/Jobster-104575948190743',
    LINKEDIN: 'https://www.linkedin.com/company/jobster-io/'
}

const apiHostname = 'https://legacy.jobster.io/api/enterprise/';
const api2Hostname = 'https://api2.jobster.io/api/enterprise/';

export const APIs = {
    LOGIN: apiHostname + 'login',
    UPLOAD_FILE: api2Hostname + 'upload_file',
    GET_FILE: api2Hostname + 'get_file',
    GET_JOBS: apiHostname + 'get_jobs',
    GET_RESUMES: apiHostname + 'get_resumes',
    GET_LISTING_COUNT: apiHostname + 'get_listing_count',
    GET_MATCH: apiHostname + 'get_match',
    GET_SKILL_MATCH: apiHostname + 'get_skill_match',
    UPDATE_STAGE: apiHostname + 'update_stage',
    DELETE_RESUME: apiHostname + 'delete_resume',
    DELETE_JOB: apiHostname + 'delete_job'
}

export const PageTypes = {
    JOB: 'job',
    RESUME: 'resume'
}